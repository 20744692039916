import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import { useSelector } from "react-redux";
import { cartHook } from "../../hooks/cart.hook";
import swal from "sweetalert";
import { googleAds } from "../../services/googleAds.service";

const ItemProduct = ({ product, provider }) => {
  const countrySetting = useSelector((state) => state.countrySetting);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [activeBoxTooltip, setBoxTooltip] = useState("");

  const showBoxTooltip = () => {
    setBoxTooltip(activeBoxTooltip === "" ? " is-show" : "");
  };

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const addPruchaseItem = () => {
    googleAds.cartEvent('conversion', product.productPrice)
    cartHook.addItem(product, provider);
    swal({
      icon: "success",
      title: "Agregado",
      text: "Se agregó el producto a tu carrito",
      button: false,
      closeOnEsc: true,
      timer: 2250,
    });
  };

  return (
    <div className="col-xl-3 col-md-4 col-6 mb-5">
      <div className="box_item_promo">
        <div className="box_img_promo">
          <img src={product.urlImage} alt="product" />
        </div>
        <div className="box_info_promo">
          <div className="name_promo">
            <p className="text fnt_medium mb-0">{product.name}</p>
            <span className="btn_info_promo trans" onClick={showBoxTooltip}>
              <i className="fas fa-info-circle"></i>
            </span>
          </div>
          <div className="price-add box_flex ">
            <span className="price">
              <small>Por solo</small>
              <h4 className="text fnt_medium mb-0 ">
                {countrySetting.currency}
                {Number(product.productPrice).toLocaleString(
                  countrySetting.cultureCode,
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }
                )}
              </h4>
            </span>
            <span onClick={addPruchaseItem}>
              <span
                className="btn_add_promo"
                id={`Tooltip${product.productId}`}
              >
                <i className="fas fa-cart-plus"></i>
              </span>
              <Tooltip
                placement="bottom"
                isOpen={tooltipOpen}
                target={`Tooltip${product.productId}`}
                toggle={toggle}
              >
                Agregar a canasta
              </Tooltip>
            </span>
          </div>
        </div>
      </div>
      <div className={`content_tooltip ${activeBoxTooltip}`}>
        <span className="btnclose_info" onClick={showBoxTooltip}>
          <i className="fas fa-times-circle"></i>
        </span>
        {product.restrictions}
      </div>
    </div>
  );
};

export default ItemProduct;
