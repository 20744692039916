import React, { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import ThePromImgs from "../assets/imgs/doodles/doodle-b.gif";

export function Doodle(props) {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <a
      href="https://pridemx.com/rewards"
      // target="_blank"
      className={`sec_doodle ${props.view}`}
    >
      <div className="box_info_doodle">
        <img
          className="img_doodle"
          src={ThePromImgs}
          alt="Especiales en PrideMx"
        />
        <div className="info_doodle">
          <h6 className="mb-0 text fnt_medium">REWARDS</h6>
          <p className="mb-0">Ser tú mismx es ser parte de la recompensa</p>
        </div>
      </div>
      <Particles
        id={props.init}
        className="drawer_particles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fullScreen: {
            enable: false,
          },
          emitters: [
            {
              position: {
                x: 0,
                y: 10,
              },
              rate: {
                quantity: 1,
                delay: 0.15,
              },
              particles: {
                move: {
                  direction: "top-right",
                  outModes: {
                    top: "none",
                    left: "none",
                    default: "destroy",
                  },
                },
              },
            },
            {
              position: {
                x: 20,
                y: -10,
              },
              rate: {
                quantity: 5,
                delay: 0.15,
              },
              particles: {
                move: {
                  direction: "top",
                  outModes: {
                    top: "none",
                    right: "none",
                    default: "destroy",
                  },
                },
              },
            },
          ],
          particles: {
            color: {
              value: ["ff6709", "#09deff", "#FC00FF", "#ffe600"],
            },
            move: {
              decay: 0.05,
              direction: "top",
              enable: true,
              gravity: {
                enable: true,
              },
              outModes: {
                top: "none",
                default: "destroy",
              },
              speed: {
                min: 1,
                max: 1,
              },
            },
            number: {
              value: 0,
            },
            opacity: {
              value: 0.95,
            },
            rotate: {
              value: {
                min: 0,
                max: 10,
              },
              direction: "random",
              animation: {
                enable: true,
                speed: 1,
              },
            },
            tilt: {
              direction: "random",
              enable: true,
              value: {
                min: 0,
                max: 10,
              },
              animation: {
                enable: true,
                speed: 2,
              },
            },
            size: {
              value: {
                min: 0,
                max: 2,
              },
              animation: {
                enable: true,
                startValue: "min",
                count: 1,
                speed: 5,
                sync: true,
              },
            },
            roll: {
              darken: {
                enable: true,
                value: 25,
              },
              enable: true,
              speed: {
                min: 5,
                max: 5,
              },
            },
            wobble: {
              distance: 30,
              enable: true,
              speed: {
                min: -7,
                max: 7,
              },
            },
            shape: {
              type: "circle",
              options: {},
            },
          },
        }}
      />
    </a>
  );
}
