import React, { useState } from 'react';
import IcoUser from '../../assets/imgs/iconos/ico_user.png'
import { userService } from '../../services/user.service';
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';

export function RecoverPassword() {
    const { register, handleSubmit } = useForm();
    const [account, setAccount] = useState("");
    const [isRecovery, setisRecovery] = useState(false);
    const [isGranted, setisGranted] = useState(false);
    const [additionalEmailVal, setAdditionalEmailVal] = useState("");
    const [isCorporateUser, setIsCorporateUser] = useState(false);

    const validateAccount = () => {
        if (account) {
            userService.validateEmailAccount(account).then((result) => {
                if (result != undefined) {
                    setIsCorporateUser(result.isCorporateUser)
                    if (result.userId != undefined)
                        setisGranted(true);

                    if (isCorporateUser)
                        setisRecovery(true);
                    else {
                        if (result.additionalEmail) {
                            setAdditionalEmailVal(result.additionalEmail);
                            setisRecovery(true);
                        }
                        else {
                            setisRecovery(false);
                        }
                    }
                }
                else {
                    swal("El usuario no está registrado.");
                }
            })
        }
        else {
            swal("Capture una dirección de correo");
        }
    }

    const handleChange = (event) => {
        setAccount({ email: event.target.value });
    }

    const onSubmit = (data, e) => {
        data.CompanyId = process.env.REACT_APP_COMPANY;
        if (isRecovery) {
            data.IsRegistered = true;
            data.AdditionalEmail = additionalEmailVal;
        }
        else {
            data.IsRegistered = false;
        }
        if (!isCorporateUser) {
            if (data.AdditionalEmail !== undefined && data.AdditionalEmail !== "") {
                userService.forgotPassword(data)
            }
            else {
                swal('Debe ingresar un correo adicional.')
            }
        }
        else {
            userService.forgotPassword(data)
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="sec_recover_pass">
            <input type="hidden" name="AdditionalEmail"  {...register("AdditionalEmail")} />
            <input type="hidden" name="Birthday" {...register("Birthday")} />
            <input type="hidden" name="IsRegistered" {...register("IsRegistered")} />

            <h2 className="ttl_form text fnt_black ">RECUPERAR CONTRASEÑA</h2>
            <h6 className=" ">Ingresa tu correo electrónico y te enviaremos las instrucciones sobre cómo restablecerla.</h6>
            <div className="form-group mt-4">
                <span className="box_input_icon">
                    <input type="email" className="form-control" id="user" name="Email" aria-describedby="emailHelp" placeholder="Correo electrónico" {...register("Email")} onChange={handleChange} />
                    <span className="ico_input">
                    <i class="fa-solid fa-user"></i>
                    </span>
                </span>
                {!isGranted ?
                    <div className="mt-4">
                        <button className="btn primary" type="button" id="btnValidate" onClick={validateAccount}>Consultar</button>
                    </div>
                    : null}
            </div>
            { !(isRecovery) && isGranted && !isCorporateUser ?
                <div className="form-group mt-4">
                    <span>Es necesario efectuar una comprobación de identidad. Captura los siguientes datos: </span>
                    <br />
                    <label for="Birthday">Fecha de nacimiento</label>
                    <input className="form-control" type="date" id="Birthday" name="Birthday"  {...register("Birthday")} />
                    <label for="AdditionalEmail">Correo Electrónico para recupear contraseña</label>
                    <input type="email" className="form-control" id="AdditionalEmail" name="AdditionalEmail" aria-describedby="emailHelp" placeholder="correo electrónico" {...register("AdditionalEmail")} />
                </div>
                : null}
            {isGranted ?
                <div className="mt-4 mb-3">
                    <button type="submit" className="btn primary">Recuperar contraseña</button>
                </div>
                : null}
        </form>
    )
}