import React from 'react'
import { Link } from 'react-router-dom';

import './rewards.scss'

export function HeadRewards({ reward }) {
    return (
        <div className="wrapper_head_rewards padd">
            <div className="mb-4 divtop">
                <div className="box_ttl_opt">
                    <h2 className="mb-0 text fnt_medium">Rewards</h2>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-7">
                    <h6 className="text fnt_medium text-muted">Estado de cuenta</h6>
                    <div className="content_statistics">
                        <Link to="/rewards" className="item_statistics shadows large item25">
                            <div className="statistics">
                                <h6 className="text fnt_medium">Saldo</h6>
                                <span >
                                    <h3 className="text fnt_medium">{reward}</h3>
                                    <h6 className="text fnt_light">Puntos</h6>
                                </span>
                            </div>
                        </Link>
                        {/*<div className="item_statistics shadows item25">
                              <div className="statistics">
                                <h6 className="text fnt_medium">Vencimiento</h6>
                                <span >
                                  <h6 className="text fnt_light">12 de septiembre de 2020</h6>
                                </span>
                              </div>
                           </div>*/}

                        <Link
                            to="/redemption-history"
                            className="item_statistics shadows item25"
                        >
                            <div className="statistics">
                                <h6 className="text fnt_medium">Historial de canje</h6>
                                <span >
                                    <h6 className="text fnt_light">Ver más</h6>
                                </span>
                            </div>

                        </Link>

                        <Link
                            to="/account-status"
                            className="item_statistics shadows item25"
                        >
                            <div className="statistics">
                                <h6 className="text fnt_medium">Estado de cuenta</h6>
                                <span >
                                    <h6 className="text fnt_light">Ver más</h6>
                                </span>
                            </div>
                        </Link>

                    </div>
                </div>
                <div className="col-lg-5">
                    <h6 className="text fnt_medium text-muted">¿Cómo ganar puntos?</h6>
                    <div className="content_statistics ">
                        <div className="item_statistics shadows large item25">
                            <div className="statistics unique">
                                <div className="ico_unique">
                                    <span><i className="fas fa-award"></i></span>
                                </div>
                                <div className='textPointReward'>
                                    <p>&middot; Por <b>iniciar sesión</b> diariamente</p>
                                    <p>&middot; Coloca tu <b>foto de perfil</b> y cambia tu <b>bandera</b></p>
                                    <p>&middot; Contesta las <b>encuestas</b>, tu opinión importa</p>
                                    <p>&middot; Checa la <b>cartelera</b> y encuentra algo nuevo</p>
                                    <p>&middot; Lxs <b>emprendedores</b> tienen servicios y te dan puntos</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr />
        </div>

    )
}