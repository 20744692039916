import React, { useState, useEffect } from "react";
import { categoryServiceService } from "../../services/categoryService.service";
import { ItemSocial } from "./itemSocial";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./interestGroup.scss";

export function InterestGroup() {
  const roleName = useSelector((state) => state.userSettings.roleName);
  return (
    <section className="wrapper_embassador padd">
      <div className="mb-4 divtop">
        <div className="box_ttl_opt">
          <h2 className="mb-0 text fnt_medium w-100">Grupos de Interés</h2>
          <div className="wrp_filter_directory">
          </div>
        </div>
        <h4 className="ttls fw600 mb-4">
          <div className="box_tl">
            <span className="ico_shop">
              <i className="fas fa-user"></i>
            </span>
            <span className="pl-2 pr-2">Grupos donde nuestra comunidad es protagonista</span>
          </div>
        </h4>
      </div>

      <div className="row">
    <article className="col-lg-3 col-sm-6 mb-4">
      <div className="card shadows">
        <div className="box_content_img">
          <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/InterestGroup.png" />
        </div>
        <div className="card-content text-center">
          <h6 className="card-title mb-0 text fnt_medium name_directory">
            Cantando por la diversidad e inclusión 
          </h6> 
          <br></br>
          <div className="box_data">
              <h6 className="h_line text fnt_medium ">
                <span>DATOS DE CONTACTO</span>
              </h6>
              <div className="item_data_details">
                <span className="ico_box_detail">
                  <ion-icon name="mail-outline"></ion-icon>
                </span>
                  <a
                    className="alinks text-break text-left"
                    href={`mailto:oubmex@gmail.com`}
                  >
                    oubmex@gmail.com
                  </a>

              </div>
              <hr />
              <div className="wrapper_social_links">
                      <ItemSocial
                        socialIcoName="Instagram"
                        socialIco="logo-instagram"
                        socialUrl="https://www.instagram.com/cgcdmx/?hl=es"
                      />
       
                      <ItemSocial
                        socialIcoName="Facebook"
                        socialIco="logo-facebook"
                        socialUrl="https://www.facebook.com/corogayciudaddemexico"
                      />
              </div>
            </div>

          </div>
        </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
              <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/InterestGroup2.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium">
                Fútbol femenil compitiendo desde Tepeji del Río
              </h6> 
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>DATOS DE CONTACTO</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                      <ion-icon name="mail-outline"></ion-icon>
                    </span>
                      <a
                        className="alinks"
                        href={`mailto:ednajimenezrb@gmail.com`}
                      >
                        ednajimenezrb@gmail.com
                      </a>

                  </div>
                      
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/101934648559481/posts/399106622175614/"
                          />
                  </div>
                </div>
            </div>
          </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/InterestGroup3.jpg" />
              </div>
              <div className="card-content text-center">
                <h6 className="card-title mb-0 text fnt_medium">
                    Asociación Nacional del Deporte LGBTQ+
                </h6> 
                <br></br>
                <div className="box_data">
                    <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a
                          className="alinks"
                          href={`mailto:anadep.lgbt@gmail.com`}
                        >
                          anadep.lgbt@gmail.com
                        </a>

                    </div>
                    <hr />
                    <div className="wrapper_social_links">
                        <ItemSocial
                          socialIcoName="Instagram"
                          socialIco="logo-instagram"
                          socialUrl="https://www.instagram.com/anadelgbt/"
                        />
        
                        <ItemSocial
                          socialIcoName="Facebook"
                          socialIco="logo-facebook"
                          socialUrl="https://www.facebook.com/ANADE.LGBT"
                        />
                </div>
                  </div>
              </div>
            </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/CopaLGBT.png" />
              </div>
              <div className="card-content text-center">
                <h6 className="card-title mb-0 text fnt_medium">
                Grupo de encuentro, torneos locales y nacionales
                </h6> 
                <br></br>
                <div className="box_data">
                    <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a
                          className="alinks"
                          href={`mailto:info@copa.lgbt`}
                        >
                          info@copa.lgbt
                        </a>

                    </div>
                    <hr />
                    <div className="wrapper_social_links">
                        <ItemSocial
                          socialIcoName="Instagram"
                          socialIco="logo-instagram"
                          socialUrl="https://www.instagram.com/copalgbt/"
                        />
        
                        <ItemSocial
                          socialIcoName="Facebook"
                          socialIco="logo-facebook"
                          socialUrl="https://www.facebook.com/copalgbt/"
                        />
                </div>
                  </div>
              </div>
            </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/DragonesLGBTH.jpg" />
              </div>
              <div className="card-content text-center">
                <h6 className="card-title mb-0 text fnt_medium">
                    Fomenta el deporte y apoya a la comunidad LGBTQ+
                </h6> 
                <br></br>
                <div className="box_data">
                    <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a
                          className="alinks"
                          href={`mailto:yaurus12@gmail.com`}
                        >
                          yaurus12@gmail.com
                        </a>

                    </div>
                    <hr />
                    <div className="wrapper_social_links">
      
                        <ItemSocial
                          socialIcoName="Facebook"
                          socialIco="logo-facebook"
                          socialUrl="https://www.facebook.com/dragoneslgbth"
                        />
                </div>
                  </div>
              </div>
            </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/FederacionDiversidadDeportiva.jpg" />
              </div>
              <div className="card-content text-center">
                <h6 className="card-title mb-0 text fnt_medium">
                        Desarrollo deportivo de la comunidad LGBT+
                </h6> 
                <br></br>
                <div className="box_data">
                    <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a
                          className="alinks"
                          href={`mailto:contacto@fmdd.org.mx`}
                        >
                          contacto@fmdd.org.mx
                        </a>

                    </div>
                    <hr />
                    <div className="wrapper_social_links">
                        <ItemSocial
                          socialIcoName="globe-outline"
                          socialIco="globe-outline"
                          socialUrl="http://fmdd.org.mx"
                        />
        
                        <ItemSocial
                          socialIcoName="Facebook"
                          socialIco="logo-facebook"
                          socialUrl="https://www.facebook.com/FMDDAC"
                        />
                </div>
                  </div>
              </div>
            </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/Ajolotitos.jpg" />
              </div>
              <div className="card-content text-center">
                <h6 className="card-title mb-0 text fnt_medium">
                    Practica natación en un espacio seguro e inclusivo para todxs
                </h6> 
                <br></br>
                <div className="box_data">
                    <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a
                          className="alinks"
                          href={`mailto:ajolotitoscdmx@gmail.com`}
                        >
                          ajolotitoscdmx@gmail.com
                        </a>

                    </div>
                    <hr />
                    <div className="wrapper_social_links">
                        <ItemSocial
                          socialIcoName="Instagram"
                          socialIco="logo-instagram"
                          socialUrl="https://www.instagram.com/ajolotitoscdmx/"
                        />
                        <ItemSocial
                          socialIcoName="Facebook"
                          socialIco="logo-facebook"
                          socialUrl="https://www.facebook.com/Ajolotitos-CDMX-109272321261374"
                        />
                </div>
                  </div>
              </div>
            </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/IdentidadesTrans.jpg" />
              </div>
              <div className="card-content text-center">
                <h6 className="card-title mb-0 text fnt_medium">
                    Defensa de los derechos humanos de las poblaciones Trans
                </h6> 
                <br></br>
                <div className="box_data">
                    <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a className="alinks" href={`mailto:centro.apoyo.identidades.trans@gmail.com`} >
                          centro.apoyo.identidades.trans@gmail.com
                        </a>

                    </div>
                    <hr />
                    <div className="wrapper_social_links">
                      <ItemSocial
                          socialIcoName="Instagram"
                          socialIco="logo-instagram"
                          socialUrl="https://www.instagram.com/centro.apoyo.identidades.trans/"
                        />
                        <ItemSocial
                          socialIcoName="Facebook"
                          socialIco="logo-facebook"
                          socialUrl="https://www.facebook.com/CentroApoyoIdentidadesTrans"
                        />
                        <ItemSocial
                          socialIcoName="Twitter"
                          socialIco="logo-twitter"
                          socialUrl="https://twitter.com/CentroTrans"
                        />
                </div>
                  </div>
              </div>
            </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/ViveTransInterestGroup.png" />
              </div>
              <div className="card-content text-center">
                <h6 className="card-title mb-0 text fnt_medium">
                    Equipo de fútbol creado para personas diversas
                </h6> 
                <br></br>
                <div className="box_data">
                    <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a className="alinks" href={`mailto:futgreeicyuli@gmail.com`} >
                          futgreeicyuli@gmail.com
                        </a>

                    </div>
                    <hr />
                    <div className="wrapper_social_links">
                        <ItemSocial
                          socialIcoName="Facebook"
                          socialIco="logo-facebook"
                          socialUrl="https://www.facebook.com/vivetransmorelos"
                        />
                    </div>
                  </div>
              </div>
            </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/ColibrisInterestGroup.png" />
              </div>
              <div className="card-content text-center">
                <h6 className="card-title mb-0 text fnt_medium">
                    Equipo de fútbol femenil derribando prejuicios y estereotipos
                </h6> 
                <br></br>
                <div className="box_data">
                    <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a className="alinks" href={`mailto:chendosm1594@gmail.com`} >
                          chendosm1594@gmail.com
                        </a>

                    </div>
                    <hr />
                    <div className="wrapper_social_links">
                        <ItemSocial
                          socialIcoName="Facebook"
                          socialIco="logo-facebook"
                          socialUrl="https://www.facebook.com/Colibris-Lgbt-tlahuac-107835651843559/"
                        />
                    </div>
                  </div>
              </div>
            </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/LobasInterestGroup.png" />
              </div>
              <div className="card-content text-center">
                <h6 className="card-title mb-0 text fnt_medium">
                    Equipo de fútbol femenil derribando estereotipos
                </h6> 
                <br></br>
                <div className="box_data">
                    <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a className="alinks" href={`mailto:lobas.mexico.lgbt@gmail.com`} >
                        lobas.mexico.lgbt@gmail.com
                        </a>

                    </div>
                    <hr />
                    <div className="wrapper_social_links">
                        <ItemSocial
                          socialIcoName="Facebook"
                          socialIco="logo-facebook"
                          socialUrl="https://www.facebook.com/profile.php?id=100063656198745"
                        />
                        <ItemSocial
                          socialIcoName="Instagram"
                          socialIco="logo-instagram"
                          socialUrl="https://www.instagram.com/p/ChlO4onOAHA/?igshid=MDJmNzVkMjY="
                        />
                    </div>
                  </div>
              </div>
            </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/chakalososInterestGroup.jpg" />
              </div>
              <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium">
              Equipo de Básquet para “osos” unidos por la diversidad
                  <br></br>
                  <br></br>
                </h6> 
                <h6 className="card-title mb-0 text fnt_medium">
                  
                </h6> 
                <br></br>
                <div className="box_data">
                    {/* <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6> */}
                    {/* <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a className="alinks" href={`mailto:lobas.mexico.lgbt@gmail.com`} >
                        lobas.mexico.lgbt@gmail.com
                        </a>
                    </div> */}
                    <hr />
                    <div className="wrapper_social_links">
                        <ItemSocial
                          socialIcoName="Facebook"
                          socialIco="logo-facebook"
                          socialUrl=" https://www.facebook.com/clubbasquetchakalosos/"
                        />
                    </div>
                  </div>
              </div>
            </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/spycyInterestGroup.jpg" />
              </div>
              <div className="card-content text-center">
                <h6 className="card-title mb-0 text fnt_medium">
                  Primer equipo inclusivo de Rugby en Querétaro 
                </h6> 
                <br></br>
                <div className="box_data">
                    <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a className="alinks" href={`mailto:diegobadillabaeza@gmail.com/`} >
                            diegobadillabaeza@gmail.com
                        </a>
                    </div>
                    <hr />
                    <div className="wrapper_social_links">
                        <ItemSocial
                          socialIcoName="Instagram"
                          socialIco="logo-instagram"
                          socialUrl="https://instagram.com/spicy_rugby_qro?igshid=YmMyMTA2M2Y="
                        />
                    </div>
                  </div>
              </div>
            </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/SpicyBarbariansInterestGroup.jpg" />
              </div>
              <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium"> 
              Primer equipo inclusivo de Rugby en CDMX
              </h6> 
                    
                  <br></br>
                <br></br>
                <div className="box_data">
                    <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                     {/* <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a className="alinks" href={`mailto:lobas.mexico.lgbt@gmail.com`} >
                        lobas.mexico.lgbt@gmail.com
                        </a>
                    </div> */}
                    <hr />
                    <div className="wrapper_social_links">
                    <ItemSocial
                          socialIcoName="Instagram"
                          socialIco="logo-instagram"
                          socialUrl="https://www.instagram.com/spicybarbarians/"
                        />
                        <ItemSocial
                          socialIcoName="Facebook"
                          socialIco="logo-facebook"
                          socialUrl="https://www.facebook.com/spicyrugby"
                        />
                    </div>
                  </div>
              </div>
            </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/CuentaConmigoInterestGroup.jpg" />
              </div>
              <div className="card-content text-center">
                <h6 className="card-title mb-0 text fnt_medium">
                  Respeto a la diversidad sexual y equidad de género
                </h6> 
                <br></br>
                <div className="box_data">
                    <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a className="alinks" href={`mailto:midonativo@cuentaconmigo.org.mx`} >
                          midonativo@cuentaconmigo.org.mx
                        </a>

                    </div>
                    <hr />
                    <div className="wrapper_social_links">
                        <ItemSocial
                          socialIcoName="Facebook"
                          socialIco="logo-facebook"
                          socialUrl="https://www.facebook.com/diversidadconmigo"
                        />
                        <ItemSocial
                          socialIcoName="Instagram"
                          socialIco="logo-instagram"
                          socialUrl="https://www.instagram.com/cuentaconmigodiversidadsexual/"
                        />
                    </div>
                  </div>
              </div>
            </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/InterestGroups/ESCUDOMUXES2021.jpg" />
              </div>
              <div className="card-content text-center">
                <h6 className="card-title mb-0 text fnt_medium">
                Primer equipo de fútbol incluyente en México
                </h6> 
                <br></br>
                <div className="box_data">
                    <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a className="alinks" href={`mailto:esmeralda@clubdeportivomuxes.org`} >
                        esmeralda@clubdeportivomuxes.org
                        </a>

                    </div>
                    <hr />
                    <div className="wrapper_social_links">
                    <ItemSocial
                          socialIcoName="web"
                          socialIco="globe-outline"
                          socialUrl="https://clubdeportivomuxes.org"
                        />
                      <ItemSocial
                          socialIcoName="Instagram"
                          socialIco="logo-instagram"
                          socialUrl="https://www.instagram.com/clubmuxes/"
                        />
                        <ItemSocial
                          socialIcoName="Facebook"
                          socialIco="logo-facebook"
                          socialUrl="https://www.facebook.com/ClubDeportivoMuxes/"
                        />
                        <ItemSocial
                          socialIcoName="Twitter"
                          socialIco="logo-twitter"
                          socialUrl="https://twitter.com/ClubMuxes"
                        />
                        <ItemSocial
                          socialIcoName="TikTok"
                          socialIco="logo-tiktok"
                          socialUrl="https://www.tiktok.com/@clubmuxesoficial"
                        />
                        <ItemSocial
                          socialIcoName="Youtube"
                          socialIco="logo-youtube"
                          socialUrl="https://www.youtube.com/c/ClubDeportivoMuxes"
                        />
                        
                </div>
                  </div>
              </div>
            </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/InterestGroups/balletfolklorico.jpeg" />
              </div>
              <div className="card-content text-center">
                <h6 className="card-title mb-0 text fnt_medium">
                Ballet Folklórico Lgbttti Jalisco es Diverso
                </h6> 
                <br></br>
                <div className="box_data">
                    <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a className="alinks" href={`mailto:manuelcobian333@gmail.com`} >
                        manuelcobian333@gmail.com
                        </a>

                    </div>
                    <hr />
                    <div className="wrapper_social_links">
                    <ItemSocial
                          socialIcoName="web"
                          socialIco="globe-outline"
                          socialUrl="https://jaliscoesdiverso.wordpress.com/?fbclid=IwAR2YgUwVtDz0MMnG2InORg1mxZ5PueuKBEk0hyfIlmaSyKL4BszXhRC9xLA%2F"
                        />
                      <ItemSocial
                          socialIcoName="Instagram"
                          socialIco="logo-instagram"
                          socialUrl="https://www.instagram.com/jalisco.es.diverso/"
                        />
                        <ItemSocial
                          socialIcoName="Facebook"
                          socialIco="logo-facebook"
                          socialUrl="https://www.facebook.com/jaliscoesdiverso"
                        />
                        <ItemSocial
                          socialIcoName="Twitter"
                          socialIco="logo-twitter"
                          socialUrl="https://twitter.com/jaliscoes"
                        />
                    </div>
                  </div>
              </div>
            </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/InterestGroups/SharkesLogo.jpg" />
              </div>
              <div className="card-content text-center">
                <h6 className="card-title mb-0 text fnt_medium">
                Club deportivo perteneciente a la comunidad LGBTIQ+
                </h6> 
                <br></br>
                <div className="box_data">
                    <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a className="alinks" href={`mailto:sharkesnatacion@gmail.com`} >
                        sharkesnatacion@gmail.com
                        </a>
                    </div>
                    <hr />
                    <div className="wrapper_social_links">
                      <ItemSocial
                          socialIcoName="Instagram"
                          socialIco="logo-instagram"
                          socialUrl="https://www.instagram.com/sharkeslgbtiq/"
                        />
                        <ItemSocial
                          socialIcoName="Facebook"
                          socialIco="logo-facebook"
                          socialUrl="https://www.facebook.com/SharkesLgbtiq"
                        />
                        <ItemSocial
                          socialIcoName="TikTok"
                          socialIco="logo-tiktok"
                          socialUrl="https://www.tiktok.com/@sharkeslgbtq"
                        />
                    </div>
                  </div>
              </div>
            </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/InterestGroups/TrevorProject.png" />
              </div>
              <div className="card-content text-center">
                <h6 className="card-title mb-0 text fnt_medium">
                Atención en crisis y prevención del suicidio para juventudes LGBTQ+
                </h6> 
                <br></br>
                <div className="box_data">
                <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a className="alinks" href={`mailto:info.mx@thetrevorproject.org`} >
                        info.mx@thetrevorproject.org
                        </a>
                    </div>
                    <hr />
                    <div className="wrapper_social_links">
                      <ItemSocial
                          socialIcoName="Instagram"
                          socialIco="logo-instagram"
                          socialUrl="https://www.instagram.com/trevorprojectmx/"
                        />
                        <ItemSocial
                          socialIcoName="Facebook"
                          socialIco="logo-facebook"
                          socialUrl="https://www.facebook.com/TrevorProjectMX"
                        />
                        <ItemSocial
                          socialIcoName="web"
                          socialIco="globe-outline"
                          socialUrl="https://www.thetrevorproject.mx/"
                        />
                        <ItemSocial
                          socialIcoName="Ayuda"
                          socialIco="globe-outline"
                          socialUrl="https://www.thetrevorproject.mx/ayuda"
                        />
                    </div>
                  </div>
              </div>
            </div>
          </article>
          <article className="col-lg-3 col-sm-6 mb-4">
            <div className="card shadows">
              <div className="box_content_img">
                <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/InterestGroups/GrupoInteresYaaj.jpg" />
              </div>
              <div className="card-content text-center">
                <h6 className="card-title mb-0 text fnt_medium">
                  Organización que defiende los derechos de la comunidad LGBT+
              </h6> 
                <br></br>
                <div className="box_data">
                    <h6 className="h_line text fnt_medium ">
                      <span>DATOS DE CONTACTO</span>
                    </h6>
                    <div className="item_data_details">
                      <span className="ico_box_detail">
                        <ion-icon name="mail-outline"></ion-icon>
                      </span>
                        <a className="alinks" href={`mailto:yaajmexico@gmail.com`} >
                          yaajmexico@gmail.com
                        </a>
                    </div>
                    <hr />
                    <div className="wrapper_social_links">
                    <ItemSocial
                          socialIcoName="LinkedIn"
                          socialIco="logo-linkedin"
                          socialUrl="http://www.linkedin.com/company/yaajmexico"
                        />
                      <ItemSocial
                          socialIcoName="Instagram"
                          socialIco="logo-instagram"
                          socialUrl="https://www.instagram.com/yaajmexico/"
                        />
                        <ItemSocial
                          socialIcoName="Facebook"
                          socialIco="logo-facebook"
                          socialUrl="https://www.facebook.com/yaajmexico"
                        />
                        <ItemSocial
                          socialIcoName="TikTok"
                          socialIco="logo-tiktok"
                          socialUrl="https://www.tiktok.com/@yaajmexico"
                        />
                    </div>
                  </div>
              </div>
            </div>
          </article>
      </div>
    </section>
);}
