import * as Yup from "yup";
import moment from "moment";

export const schemaPage1 = Yup.object().shape(
  {
    email: Yup.string()
      .required("Este campo es requerido")
      .email("No parece un email valido"),
    password: Yup.string()
      .required("Este campo es requerido")
      .matches(
        /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/g,
        "Debe tener al menos 8 caracteres, al menos un símbolo, al menos un número, al menos una mayúscula y minúscula"
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Las contraseñas deben coincidir")
      .required("Este campo es requerido"),
    firstName: Yup.string()
      .required("Este campo es requerido")
      .matches(/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g, "Este campo solo admite letras"),
    middleName: Yup.string()
      .required("Este campo es requerido")
      .matches(/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g, "Este campo solo admite letras"),
    lastName: Yup.string()
      .required("Este campo es requerido")
      .matches(/^[ a-zA-ZÀ-ÿ\u00f1\u00d1]*$/g, "Este campo solo admite letras"),
    // gender: Yup.string().nullable().required("Este campo es requerido"),
    birthday: Yup.date()
      .typeError("Este campo es requerido")
      .min("1900-01-01", "El valor debe ser mayor o igual a 01/01/1900")
      .max(
        moment(new Date()).format("YYYY-MM-DD"),
        "El valor debe ser menor o igual a " +
          moment(new Date()).format("DD/MM/YYYY")
      )
      .required("Este campo es requerido"),
    birthday: Yup.string().test(
      "birthday",
      "Debes ser mayor de 18 años para pertenecer a esta comunidad",
      value => {
        return moment().diff(moment(value),'years') >= 18;
      }),
    mobile: Yup.string().when("mobile", {
      is: (value) => value?.length,
      then: (rule) =>
        rule.matches(/^[0-9]{10}$/g, "No parece un número de teléfono valido"),
    }),
    mobileConfirm: Yup.string()
    .oneOf([Yup.ref("mobile"), null], "Los números de teléfono deben coincidir").typeError("Los campos deben coincidir")
    .required("Este campo es requerido"),
    // phoneNumber: Yup.string().when("phoneNumber", {
    //   is: (value) => value?.length,
    //   then: (rule) =>
    //     rule.matches(/^[0-9]{10}$/g, "No parece un número de teléfono valido"),
    // }),
    extension: Yup.string(),
  },
  [
    // Add Cyclic deps here because when require itself
    ["mobile", "mobile"],
    ["phoneNumber", "phoneNumber"],
  ]
);

export const schemaPage2 = Yup.object().shape({
  //   email: Yup.string()
  //     .required("Este campo es requerido")
  //     .email("No parece un email valido"),
  //   password: Yup.string()
  //     .matches(
  //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,15}$/g,
  //       "- La contraseña deben tener un largo mínimo de 8 caracteres. /n - La contraseña debe contener al menos un símbolo. /n - La contraseña debe incluir al menos una letra minúscula ('a'-'z'). /n - La contraseña debe incluir al menos una letra MAYÚSCULA ('A'-'Z')."
  //     )
  //     .required("Este campo es requerido"),
  //   confirmPassword: Yup.string()
  //     .oneOf([Yup.ref("password"), null], "Las contraseñas deben coincidir")
  //     .required("Este campo es requerido"),
});

export const schemaPage3 = Yup.object().shape(
  {
    Street: Yup.string().required("Este campo es requerido"),
    PostalCode: Yup.string().required("Este campo es requerido"),
    CountryStateTypeId: Yup.string().required("Este campo es requerido"),
    fechanac: Yup.string().matches(/^[0-9]{6}$/g, "Solo numeros"),
  },
  [
    // Add Cyclic deps here because when require itself
    ["fechanac", "fechanac"],
  ]
);
