import React, { Fragment, useEffect, useState } from "react";
import useInputSingleForm from "../components/CustonHooks/inputFormHook";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { callApi } from './service'

export const metaPixelService = {
    trackEvent,
    trackRegisterEvent,
    saveEvent,
    customEvent,
    simpleEvent
}

const token = process.env.REACT_APP_PIXEL_TOKEN
const pixelId = process.env.REACT_APP_PIXEL_ID


const testData = {
    "id": 0,
    "section": 'LearningCenter',
    "content_type": 'Link',
}

async function trackEvent(type, name, data) {
    let email = localStorage["email"];
    let eventID = CreateUUID();
    let userData = {em: email}
    window.fbq("init", pixelId, userData , { eventID: eventID });
    window.fbq(type, name, data);

    let pixelDataObject = {
        profileUserId : null,
        pixelUserInfo : userData,
        pixelCustomData : data
    }

    var params = {
        type: name,
        eventId : eventID,
        pixelData: pixelDataObject
    }
    debugger

    callApi('/backend/PixelTrack', 'POST', params, {
        SkipValidationToken: true,
      }).then((result) => {
        if (result.responseCode !== '00') {
            console.log('error',result);
        }
    });
}

async function trackRegisterEvent(type, name, data) {
    let userInfo = data.RegisterPixelUserInfo;
    window.fbq("init", pixelId, { em : userInfo.em, fn: userInfo.fn, ln: userInfo.ln, db: userInfo.db });
    window.fbq(type, name, { content_name : data.RegisterPixelCustomData.content_name, value : data.RegisterPixelCustomData.value, status: data.RegisterPixelCustomData.status},  {eventID: name + '_' + data.ProfileUserId});

    var params = {
        type: name,
        pixelData: data
    }

    callApi('/backend/PixelTrackRegister', 'POST', params, {
        SkipValidationToken: true,
      }).then((result) => {
        if (result.responseCode !== '00') {
            console.log('error',result);
        }
    });
}

async function saveEvent(type, name, data) {
    data = {...data, access_token: token}
    window.fbq("init", pixelId);
    window.fbq(type, name, data);
}

async function customEvent(type, name, data = null) {
    window.fbq("init", pixelId);
    if(data){
        window.fbq(type, name, data);
    } else {
        window.fbq(type, name);
    }
}

async function simpleEvent(type, name, testCode = null) {
    window.fbq("init", pixelId);
    if(testCode){
        window.fbq(type, name, {test_event_code: testCode});
    } else {
        window.fbq(type, name);
    }
    var params = {
        type: name,
        pixelData: {}
    }
    callApi('/backend/PixelTrack', 'POST', params).then((result) => {
        if (result.responseCode !== '00') {
            console.log('error',result);
        }
    });
}

function CreateUUID() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  }
// fbq('track', 'ViewContent', {
//     content_name: 'Content Title',
//     content_category: 'Learning',
//     content_ids: ['1234'],
//     content_type: 'product',
//     value: 0.50,
//     currency: 'MXN'
//    }); {test_event_code: 'test123'}
