import React, { useState } from "react";
import Cards from "../../assets/imgs/red-pay.png";
import visaLogo from "../../assets/imgs/visa_logo.png";
import masterCardLogo from "../../assets/imgs/mastercard_logo.png";
import amexLogo from "../../assets/imgs/amex_logo.png";
import BtnRP from "../../assets/imgs/red-pay-logo.png";
import { paymentService } from "../../services/payment.service";
import { metaPixelService } from "../../services/metaPixel.service";

import "./registerServices.scss";

export function FormCheckoutPay(props) {
  console.log(Cards)
  const [datos, setDatos] = useState({});
  const [cardTypeName, setcardTypeName] = useState("");
  const [cvvMaxLenght, setcvvMaxLenght] = useState(3);
  const [cardImage, setCardImage] = useState(Cards);
  const [cvvPlaceholder, setCvvPlaceholder] = useState("xxx");

  const card_american = /^(?:3[47][0-9]{13})$/;
  const card_visa = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
  const card_master =
    /^5[1-5]\d{14}$|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))\d{12}$/;

  const handleInputChange = (event) => {
    
    if (event.target.name === "cardNumber"){
      let definedCardType = fnGetCardTypeReq(event.target.value)
      setcardTypeName(definedCardType);
      console.log(cardTypeName)
    }
    setDatos({
      ...datos,
      [event.target.name]: event.target.value,
    });
    props.Datepersonal(
      {
        ...datos,
        [event.target.name]: event.target.value,
      },
      "pay"
    );
  };

  function fnGetCardTypeReq(cardNumber) {
    const card_american = /^(?:3[47][0-9]{13})$/;
    const card_visa = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    const card_master = /^5[1-5]\d{14}$|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))\d{12}$/;
    let cardType = "";
    if (cardNumber.toString().match(card_visa)) {
      cardType = "VISA";
      setcvvMaxLenght(3);
      setCardImage(visaLogo);
      setCvvPlaceholder("xxx");
    } else if (cardNumber.toString().match(card_master)) {
      cardType = "MASTERCARD";
      setcvvMaxLenght(3);
      setCardImage(masterCardLogo);
      setCvvPlaceholder("xxx");
    } else if (cardNumber.toString().match(card_american)) {
      cardType = "AMERICAN EXPRESS";
      setcvvMaxLenght(4);
      setCardImage(amexLogo);
      setCvvPlaceholder("xxxx");
    }
    else {
      cardType = "UNDEFINED";
      setcvvMaxLenght(3);
      setCardImage(Cards);
      setCvvPlaceholder("xxx");
    }
    return cardType;
  }

  function getCardType(cardNumber) {
    const card_american = /^(?:3[47][0-9]{13})$/;
    const card_visa = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    const card_master =/^5[1-5]\d{14}$|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))\d{12}$/;
    let cardType = "";
    if (cardNumber.match(card_visa)) {
      cardType = "001";
    } else if (cardNumber.match(card_master)) {
      cardType = "002";
    } else if (cardNumber.match(card_american)) {
      cardType = "003";
    }
    return cardType;
  }

  function enviadatos() {
    //debugger;
    var pixelData = {content_category: 'Directorio', currency: 'MXN', value: 10.0}
    console.log(pixelData)
    metaPixelService.trackEvent('track','AddPaymentInfo', pixelData)
    props.Datepersonal(datos, "pay");
    datos.cardType = getCardType(datos.cardNumber);
    debugger
    paymentService
      .sendRedPayCardToken(datos, props.data.directoryInfoId)
      .then((result) => {
        console.log(result);
      });
    props.toggleView();
  }
  return (
    <form onSubmit={enviadatos}>
      <div className="bar_step_reg">
        <div className="box_item_step step_active">
          <span className="item_step">1</span>
        </div>
        <div className="box_item_step step_active">
          <span className="item_step">2</span>
        </div>
        <div className="box_item_step step_active">
          <span className="item_step">3</span>
        </div>
      </div>
      <div className="content_shadows_form">
        <div className="text-right mb-2">
          <span className="goback trans " onClick={props.toBack}>
            <ion-icon name="chevron-back"></ion-icon>
            <span>Volver</span>
          </span>
        </div>
        <div className="row ">
          <div className="col-md-12 box_info_save">
            <h6 className="text fnt_medium mb-3">
              <span>Datos de tarjeta</span>
              <img src={cardImage} alt="" />
            </h6>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="form-group">
              <label htmlFor="">Número de tarjeta</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="CardNumber"
                placeholder="xxxx xxxx xxxx xxxx"
                maxLength={16}
                minLength={15}
                onKeyPress={(e) => {
                  e = e || window.event;
                  var charCode = e.keyCode || e.which;
                  var charStr = String.fromCharCode(charCode);
                  if (isNaN(charStr)) e.preventDefault();
                }}
                required={true}
                name="cardNumber"
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="form-group">
              <label htmlFor="">Nombre(s)</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="FirstName"
                required={true}
                placeholder="Nombre"
                name="firstName"
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="form-group">
              <label htmlFor="">Apellidos</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="LastName"
                required={true}
                placeholder="Apellidos"
                name="LastName"
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="col-md-4 col-sm-6">
            <div className="form-group">
              <label htmlFor="">Mes de expiración</label>
              <input
                type="number"
                className="form-control form-control-sm"
                id="CardExpirationMonth"
                placeholder="xx"
                max={12}
                min={1}
                required={true}
                name="CardExpirationMonth"
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="form-group">
              <label htmlFor="">Año de expiración</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="CardExpirationYear"
                placeholder="xxxx"
                maxLength={4}
                minLength={4}
                required={true}
                onKeyPress={(e) => {
                  e = e || window.event;
                  var charCode = e.keyCode || e.which;
                  var charStr = String.fromCharCode(charCode);
                  if (isNaN(charStr)) e.preventDefault();
                }}
                name="cardExpirationYear"
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="form-group">
              <label htmlFor="">CVV</label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="Cvv"
                max={999}
                pattern="(/[0-9]{3}$/g"
                minLength={cvvMaxLenght}
                maxLength={cvvMaxLenght}
                required={true}
                placeholder={cvvPlaceholder}
                name="cvv"
                onKeyPress={(e) => {
                  e = e || window.event;
                  var charCode = e.keyCode || e.which;
                  var charStr = String.fromCharCode(charCode);
                  if (isNaN(charStr)) e.preventDefault();
                }}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-12 drawer_info_aprob">
            <span>
              El cobro será efectuado hasta obtener la aprobación de tu
              servicio, una vez publicado, el cargo se realizará de forma
              automática.
            </span>
          </div>
          <div className="col-md-12 box_pay_sec">
            <div className="dinfotex">
              <div>powered by </div>
              <img
                src={BtnRP}
                alt="Métodos de pagos en Red Voucher Online, Red Pay Pasarela de pago, México"
              />
            </div>
            <button type="submit" className="btn primary trans">
              Terminar Registro
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}
