import React, { useState, useEffect } from "react";
import { ItemMorePost } from "./itemMorePost";
import { companyPostViewService } from "../../services/compayPostView.service";

export function AsideOurCompany({
  getPost,
  postType,
  activeAsideSurvey,
  onCloseClick,
  postCategoryId,
  setLastFiter,
}) {
  const [filter, setFilter] = useState([]);
  const [filterId, setFilterId] = useState(0);

  useEffect(() => {
    companyPostViewService
      .getDateFilters(postType, postCategoryId)
      .then((result) => {
        if (result) {
          setFilter([]);
          if (result && result.length > 0) {
            setFilter(result);
            let lastItem = result[0];
            let lastMonth = lastItem.months[0];
            lastMonth.postType = postCategoryId;
            setLastFiter(lastMonth);
            setFilterId(postCategoryId)
          }
        }
      });
  }, [postType, postCategoryId]);

  return (
    <div className={`sec_aside_our_company trans ${activeAsideSurvey}`}>
      <span className="closex" onClick={onCloseClick}>
        <i className="fas fa-times"></i>
      </span>
      <div className="sec_more_post">
        <h5 className="mb-0 text fnt_medium text-muted pb-2">
          Más publicaciones
        </h5>
        <div className="box_items_years">
          {filter.map((item) => (
            <ItemMorePost filter={item} getPost={getPost} key={item.year} />
          ))}
        </div>
      </div>
    </div>
  );
}
