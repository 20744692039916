import React, { useState, useEffect } from "react";
import { ItemSocial } from "./itemSocial";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./prideMusic.scss";

export function PrideMusic() {
  const roleName = useSelector((state) => state.userSettings.roleName);
  const [sectionOn, setSectionOn] = useState(true);
  const fSectionOn = () => {
    setSectionOn(!sectionOn);
  };
  return (
    <section className="wrapper_embassador padd">
      <div className="mb-4 divtop">
        <div className="box_ttl_opt">
          <h2 className="mb-0 text fnt_medium w-100">Pride Music</h2>
          <div className="wrp_filter_directory">
          </div>
        </div>
        <h4 className="ttls fw600 mb-4">
          <div className="box_tl">
            <span className="ico_shop">
              <i className="fas fa-music"></i>
            </span>
            <span className="pl-2 pr-2">Música por y para nuestra comunidad</span>
          </div>
        </h4>
      </div>
        <div className="box_filter_messg">
          <div className=" box_ordering">
            <div>
            <p className="drawer_reg_sex w-100 mt-2">
              ¿Conoces a algún artista o grupo LGBTQ+ que podríamos agregar a esta sección? Notifícalo a Administrador PrideMx.
            </p>
            </div>
          </div>


        </div>

      <div className="row">
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/PrideMxCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Pride 2022
              </h6> 
              <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Baila con todo tu corazón</i>
              </h6> 
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/playlist/4cGOVI95ZEQXZWddG0o3PL?si=845c6d01ff5d418c`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://instagram.com/somos_pridemx"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/SomosPrideMx/"
                          />
                          <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-twitter"
                            socialUrl="https://twitter.com/somospridemx"
                          />
                          <ItemSocial
                            socialIcoName="Linkedin"
                            socialIco="logo-linkedin"
                            socialUrl="https://www.linkedin.com/company/pridemx/"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/June2022Cover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                June 2022
              </h6> 
              <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebración del Orgullo Mundial</i>
              </h6> 
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/playlist/0lpxS3kqZ4nOnOx4BB6NuR?si=b331ac2552cc4159`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://instagram.com/somos_pridemx"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/SomosPrideMx/"
                          />
                          <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-twitter"
                            socialUrl="https://twitter.com/somospridemx"
                          />
                          <ItemSocial
                            socialIcoName="Linkedin"
                            socialIco="logo-linkedin"
                            socialUrl="https://www.linkedin.com/company/pridemx/"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/PoderFemeninoCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                ¡Poder Femenino!
              </h6> 
              <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6> 
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/playlist/3FuLGXa3KjI059UcAxvfKL?si=8b3b8ce455414e74`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://instagram.com/somos_pridemx"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/SomosPrideMx/"
                          />
                          <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-twitter"
                            socialUrl="https://twitter.com/somospridemx"
                          />
                          <ItemSocial
                            socialIcoName="Linkedin"
                            socialIco="logo-linkedin"
                            socialUrl="https://www.linkedin.com/company/pridemx/"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/chavelavargasCover.webp" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Chavela Vargas
              </h6> 
              <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>La dama del poncho rojo</i>
              </h6> 
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/0WC6O2ZzUcDYvcmt2mGh8c?si=6soE-zp5RoGfouxdNdTVFg&nd=1`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  {/* <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/reneegoust/?hl=es"
                          />
                          <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-twitter"
                            socialUrl="https://twitter.com/reneegoust"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/reneegoust"
                          />
                  </div> */}
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/juanjoherreraCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                JuanJo Herrera
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/2btdAVbB0ggZ1UekXmybmN?si=t-fyOTgoTDGsIPWbdKavVg`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/eljuanjoherrera/?hl=es"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/silviaykarmenCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Silvia y Karmen
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/0j9vq9tNfWPsdhl9sKCpEK?si=fN0GvLx9Q7yg9PXgG0Us1g`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/silviaykarmen/?hl=es"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/DanniCalvarioCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Dany Calvario
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/7hieapEQgIf8bO2eadP1MS?si=XdLjVBnuQxWgrn8aHnhZBg`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/danycalvariooficial/?hl=es"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/DaniMorenoCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Danni Moreno
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/7jbMO8GaINf90FkmWvzUAN?si=1oZyxhlBR1KtI1fxzyC3SQ&nd=1`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/dannimorenomx/?hl=es"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/AcheCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                ACHE
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/0Yd11KNwQqqx6kEmuuSfEe?si=qYRURPG1The1-lMGg-cakw&nd=1`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/yosoyache_off/?hl=es"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/ImYonickCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                I.M YONI
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/2gyrcmT4DLH5zqWBW4bKRn?si=Yjg9guD4QuyZwHZF5TUG6A&nd=1`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/imyonicreative/?hl=es"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/GardannCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Gardann
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/6HUBmmB8fwhi3Fg5qSk7kk?si=4FxqY4ZRS-Si78N1CFCoUg&nd=1`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://instagram.com/gardann_musica?igshid=YmMyMTA2M2Y="
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/WomxsCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                WOMXN
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fa-solid fa-link"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://linktr.ee/w_o_m_x_n`}
                        target="_blank" 
                      >
                        Página oficial
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/w_o_m_x_n/?hl=es"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/GrissRomeroCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Griss Romero
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/36eqG3jM0MhxTR7Cuw7BQA?si=X1LNnC9FSliA5rwz6jQfjw`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/grissromero/?hl=es"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/AdanJodorowskyCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Adan Jodorowsky
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/5qUyJ52ARWXdUNECvFHBag?si=NlCGnfhFTdemnRfEP8xRBA&nd=1`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/adanjodorowsky/?hl=es"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/lilianafelipeCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Liliana Felipe
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/4SBHg3uVTZ3UNBDSh96tcV?si=Fgsa01xOQfSTQhhEEU5log&nd=1`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/liliana_felipe_oficial/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/liliana.felipe"
                          />
                          <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-twitter"
                            socialUrl="https://twitter.com/_LilianaFelipe"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/cocorosieCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                CocoRosie
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/05fo024EFotg9songSENOZ?si=wjV4tCRASBGIbKa8Ay3oZA&nd=1`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/cocorosie_official/?hl=es"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/CocoRosie"
                          />
                          <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-twitter"
                            socialUrl="https://twitter.com/larealcocorosie?lang=es"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/ConderCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Conder
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/7pQBKb31hZsctMGIGHKFq8?si=rodLsZeMRA-bpj8WLkqhvg`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/soyconder/?hl=es"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/renegoustCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Renee Goust
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/4eV7xFoCLdPWpOx7LtF52K?si=1wiEErUdSbeLQl7bKJg1jQ&nd=1`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/reneegoust/?hl=es"
                          />
                          <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-twitter"
                            socialUrl="https://twitter.com/reneegoust"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/reneegoust"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/emiliannoCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Emilianno
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/70Tm9hbIm9nJWERO6q5K5g?si=vhsuhAAhQEy1m1RZxrvo9g&nd=1`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/cemilianogs/"
                          />
                          <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-twitter"
                            socialUrl="https://twitter.com/Cemilianogs"
                          />
                          <ItemSocial
                            socialIcoName="YoutubeChannel"
                            socialIco="logo-youtube"
                            socialUrl="https://www.youtube.com/channel/UC7My5Jad_N-X2gOoiRumX1Q"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/janetteChaoCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Jannette Chao
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/49xEbqPQALFf9rzEwO924T?si=cOlTzITKTWK3xI5qUzKUow`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="YoutubeChannel"
                            socialIco="logo-youtube"
                            socialUrl="https://www.youtube.com/c/JannetteChao"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/yorkaCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Yorka
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/71bG6LIwKMYALxV8WuIezn?si=vxrKA_ZZRjKsZJ4_h30SaA&nd=1`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-twitter"
                            socialUrl="https://twitter.com/yorkamusica"
                          />
                          <ItemSocial
                            socialIcoName="YoutubeChannel"
                            socialIco="logo-youtube"
                            socialUrl="https://www.youtube.com/user/yorkamusica"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/jaimecohenCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Jaime Kohen
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/3dPV32rIkpT2Y7w8mwkg2l?si=6LgkWH1wQoi-x3j3DSsOcQ`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/jaimekohen/"
                          />
                          <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-twitter"
                            socialUrl="https://twitter.com/JaimeKohen"
                          />
                          <ItemSocial
                            socialIcoName="YoutubeChannel"
                            socialIco="logo-youtube"
                            socialUrl="https://www.youtube.com/channel/UC8x1tmvSlImZqKhfZmoGlqw"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/ChristineCover.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Christine and the Queens
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/04vj3iPUiVh5melWr0w3xT?si=wdjz_441RwaBzW7WCIKY1Q`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/christineandthequeens/"
                          />
                          <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-twitter"
                            socialUrl="https://twitter.com/QueensChristine"
                          />
                          <ItemSocial
                            socialIcoName="YoutubeChannel"
                            socialIco="logo-youtube"
                            socialUrl="https://www.youtube.com/channel/UCvDr_Xznwh4gXIy17vVci1w"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/SamSmithCover.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Sam Smith
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/2wY79sveU1sp5g7SokKOiI?si=38OmictdQRuD826rc4BF-Q`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/samsmith/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/samsmithworld"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/TenganAndSara.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Tegan & Sara
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/5e1BZulIiYWPRm8yogwUYH?si=dDZO6EUfR5CBpJJCqCybQw&nd=1`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/teganandsara/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/TeganandSara"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/KadieElder.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Kadie Elder
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/6y2lKfMfaxfcEmHwU058FG?si=ZFL0WWMZSRyyAWCZ0f7zzw`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/kadieelder/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/kadieelder"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/JavieraMenaCover.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Javiera Mena
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/6c0qylj1D1gqcUUN2P8Ofp?si=AMEkhZ21ReOTTvLSztKPpQ`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/javieramena/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/javieramenaoficial"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/PatrickWolfCover.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Patrick Wolf
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/6s92YZUPkTK1HL1WIGrPKE?si=D5Ev7dNXRqe9AklliKZdFw`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/patrickwolf/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/patrickwolf"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/FletcherCover.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Fletcher
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/5qa31A9HySw3T7MKWI9bGg?si=BC7nw4YcS8OU2xFf8cj8MA`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/findingxfletcher/?hl=es"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/fletcher"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/EscarlataCover.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Escarlata
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/6eDMcSQvrGt2zoVFSIwmgZ?si=8R1Af-cBQ460CCC9PWtZtw`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/escarlatamu/?hl=es"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/EscarlataMU"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/LuisLauroCover.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Luis Lauro
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/5raWPpw7GjGkAbnw1BsDkh?si=JJ25P3YxSW61cHg3LkO-Pw`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/luis_lauro/?hl=es"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/luislauromusic"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/FlorAmargoCover.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Flor Amargo
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/7CcCaAFz7j2igNmrHpieIb?si=Pt5AMZH_S96mcSOP1Fme8Q`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/floramargoo/?hl=es"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/floramargo"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/AgoneyCover.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Agoney
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/4jKvQ93sRiKroRRaAI6Fqk?si=B_MqokbYRS-Bw3zXbI87Cg`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/agoney/?hl=es"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://es-la.facebook.com/AgoneyOficial/"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/AlbaRecheCover.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Alba Reche
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/4mkCQKEe89EI0kn0Q7tMyK?si=g_B_nhocRmuqrsHCsiRVsQ`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/albxreche/"
                          />
                  </div>
                </div>

              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/TroyeSivanCover.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Troye Sivan
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/3WGpXCj9YhhfX11TToZcXP?si=4dL_oOXfSo-V7mYmfvh7hw&nd=1`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/troyesivan/?hl=es"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/troyesivan"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/NataliaLacunzaCover.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Natalia Lacunza
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/3Zs59sqZJ6fWQqWbRC8bOP?si=q3P_uKyVSQWok8Z_UEitNg`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/natalialacunza/?hl=es"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/LaCuervoCover.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                La Cuervo
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/2OZl4ZTJKKT7aSXfFGlEyZ?si=EJpMdaGpRqy0LuD8F_j44w`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/lacuervorap/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/lacuervorap"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/GiorgioSiladiCover.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Giorgio Siladi
              </h6> 
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/70yXUWs47Ej8wyiChPutgj?si=VmDH_d3GT8KF1XpwJvjeYg`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/giorgiosiladi/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/giorgiosiladi"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/ErickMendozaCover.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Erick Mendoza
              </h6> 
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/4a3ZMZGCMmCEAFnRdUTdw4?si=Pm3SrEJcTD61CQLgbOnRaA`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/erick_mendozal/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/ErickMendozaOficial"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/Profile/RubyRexCover.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Ruby Rex
              </h6> 
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/4we6WrKRb1LQ16t8NVn3sU?si=5O928SxsRSWlZayFvUDt0w`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/rubyrexmusic/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/RubyRexMusic"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        {/* Noviembre */}
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Nov22/Alex%20Andwandter.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Alex Anwandter
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/0M8uC0u1YRUGCieOKmQF02?si=-s9lcucjT6aa9tPpLuT5uQ`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/alexanwandter/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/AlexAnwandterOficial"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Nov22/Arca.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Arca
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/4SQdUpG4f7UbkJG3cJ2Iyj?si=HoTpP7HsSO2dVmYwgQ6Mhw`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/arca1000000/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/arca1000000"
                          />

                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Nov22/Esteman.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Esteman
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/3ZtIhDSOuRkpDyqjx53X1R?si=KWUhKJlHSYulTNgp7JBUfg`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/estemanmusic/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/estemanmusic"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Nov22/Frank%20Ocean.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Frank Ocean
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/2h93pZq0e7k5yf4dywlkpM?si=qHWlo-KNQHWM5YWbmhhYnw`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/blonded/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/frankocean"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Nov22/Kuchimatl.jpeg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Kuchimatl
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/2sK5dD1koROa7X08rOexbP?si=p76LFewxTMOpAOu94E75E`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                        <ItemSocial
                          socialIcoName="globe-outline"
                          socialIco="globe-outline"
                          socialUrl="https://www.kuchimatl.com/"
                        />
                          <ItemSocial
                            socialIcoName="Youtube"
                            socialIco="logo-youtube"
                            socialUrl="https://youtu.be/9czKzWRXAHI"
                          />
                          
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Nov22/Lil%20Nas%20X.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Lil' Nas X
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/7jVv8c5Fj3E9VhNjxT4snq?si=7GZ3aSxTQaOX69RGpUGMqA`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/lilnasx/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/LilNasX"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Nov22/Luisa%20Almaguer%20x%20Gustavo%20Cisneros.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Luisa Almaguer
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/2EIoOPYbB27xL2XuvKmNEq?si=bPUsWmGpSluqVf0fdIzjKQ`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/almaguerluisa/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/luisaalmaguerbanda"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Nov22/Mabiland.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Mabiland
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/0M8uC0u1YRUGCieOKmQF02?si=-s9lcucjT6aa9tPpLuT5uQ`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/mabiland/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/mabiland"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Nov22/Planeta%20No.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Planeta No
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/47hetBUhKhfBmk8nXeriqN?si=-KThKSaWSMGGdNXnfapNzw`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/planetano/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/Planetano"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Nov22/Rubio.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Rubio
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/79YjWaAoD88XGLETIsnnQV?si=afq902iwQNKO6iZgCkPvtA`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/rubiomusic_/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/franstraubemusic"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Nov22/Years%26Years.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Years & Years
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/5vBSrE1xujD2FXYRarbAXc?si=yfZ1TUApRoizd54z3PHqPw`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/yearsandyears/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/YearsAndYears"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        {/* Diciembre */}
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Dic22/ISBO.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              ISBO
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/7IktlLPzfDTRCRUMIwb9A6`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/isbo_official/"
                          />
                          <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/isboofficial/"
                          />
                          <ItemSocial
                          socialIcoName="Youtube"
                          socialIco="logo-youtube"
                          socialUrl="https://www.youtube.com/ISBO%20Official%20Channel"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
       
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Dic22/lorielaterveen.jpeg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Loriela Ter-Veen
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/album/7u0SOTcKsVpuyRgQ3lRyxq`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/lorielaterveen/"
                          />
                          <ItemSocial
                            socialIcoName="globe-outline"
                            socialIco="globe-outline"
                            socialUrl="https://orcd.co/ltmiel"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Dic22/MarianaBo.jpeg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Mariana Bo
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/2cFzYhiHqYS7o8ZIM9WD22`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/djmarianabo/?hl=es"
                          />
                          <ItemSocial
                            socialIcoName="Youtube"
                            socialIco="logo-youtube"
                            socialUrl="https://www.youtube.com/c/MarianaBO"
                          />
                           <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-twitter"
                            socialUrl="https://twitter.com/djmarianabo"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Dic22/nickbolt.jpeg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Nick Bolt
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/2263RSxXgCVimLKos07Atw`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/nick_bolt/?hl=es"
                          />
                          <ItemSocial
                            socialIcoName="globe-outline"
                            socialIco="globe-outline"
                            socialUrl="https://linktr.ee/Nickbolt"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Dic22/villanoantillano.jpeg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Villano Antillano
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/1pi7nGhOM7PTHR5YEgXVGq`}
                        target="_blank" 
                      >
                        Spotify
                      </a>
                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/villanoantillano/?hl=es"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        {/* Enero */}
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Ene23/FabiolaRuano.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Fabiola Ruano
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/5Znu42tC5tuwJatNswa51K?si=RzAajsrsRYWl2l-sG1FjEQ`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                          socialIcoName="Youtube"
                          socialIco="logo-youtube"
                          socialUrl="https://www.youtube.com/channel/UC3UFx05qR24-vg-SxJU1kEg"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Ene23/MauHernandez.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Mau Hernandez
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/50sqO5X11icFBoVNBGCupt?si=TbYOlQvsRTeTP8WjLyt7Wg`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/hdzmau/"
                          />
                          <ItemSocial
                          socialIcoName="Youtube"
                          socialIco="logo-youtube"
                          socialUrl="https://www.youtube.com/channel/UCzNKyinF1if70genjcssnxg"
                          />
                          <ItemSocial
                            socialIcoName="Música"
                            socialIco="musical-notes-outline"
                            socialUrl="https://music.apple.com/mx/artist/mau-hern%C3%A1ndez/1438633180"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Ene23/TavoSantos.png" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Tavo Santos
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/50aKBRyKnkiEAz4SsViNVu?si=EWgkiz-PTrOtbE8zg3Op8g&nd=1`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/tavosantoslink/?igshid=NTdlMDg3MTY%3D"
                          />
                          <ItemSocial
                            socialIcoName="globe-outline"
                            socialIco="globe-outline"
                            socialUrl="https://artist.link/tavosantos"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Ene23/torreblanca.jpeg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Juan Manuel Torre Blanca
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/4cKKzCOXwu1WBz76UqCeEL`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/jm_torreblanca/?hl=es"
                          />
                          <ItemSocial
                            socialIcoName="Música"
                            socialIco="musical-notes-outline"
                            socialUrl="https://music.apple.com/mx/artist/juan-manuel-torreblanca/297105048"
                          />
                          
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Ene23/VickyViolet.jpeg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Vicky Violet
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/7pbV36jEgf5zkZtyzjcaIy?si=kDpyIHMzTFy7zg5YxnXQLw`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://instagram.com/vickyviolet_music?igshid=YmMyMTA2M2Y="
                          />
                          
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Ene23/sheyla.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Sheyla Ferrera
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/album/7vwP1wUoUfhl9vhAQUhpr8?utm_source=google&utm_medium=banner,+mail,+whatsapp,+tt,+ig&utm_campaign=Álbum+Música+Diciembre+2022+Sheyla+Ferrera`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Música"
                            socialIco="musical-notes-outline"
                            socialUrl="https://music.apple.com/mx/artist/sheyla-ferrera/1519908263?l=en"
                          />
                           <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/sheylaferrera/"
                          />
                           <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-twitter"
                            socialUrl="https://twitter.com/SheylaFerrera?t=SNboTsf6C9s4xLnV2NEKsA&s=08"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Ene23/victoriavolkova.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Victoria Volkova
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  {/* <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/album/7vwP1wUoUfhl9vhAQUhpr8?utm_source=google&utm_medium=banner,+mail,+whatsapp,+tt,+ig&utm_campaign=Álbum+Música+Diciembre+2022+Sheyla+Ferrera`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div> */}
                  <br/>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Música"
                            socialIco="musical-notes-outline"
                            socialUrl="https://soundcloud.com/victoriaVi-vel-zquez-1?ref=clipboard&p=i&c=1&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
                          />
                           <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/vicovolkov/?hl=es-la"
                          />
                           <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-youtube"
                            socialUrl="https://www.youtube.com/channel/UCo3cevtOQY-qfuIpFNSRnUg"
                          />
                           <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://linktr.ee/VictoriaVolkova "
                          />
                  </div>
                </div>
                
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Ene23/laurenjauregui.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Lauren Jauregui
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/3J0BpFVUc9LeOIVeN5uNhU?si=LEWv0lDSS_O12LDMcXdhZA&nd=1`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Música"
                            socialIco="musical-notes-outline"
                            socialUrl="https://music.apple.com/us/artist/lauren-jauregui/935727844"
                          />
                           <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/laurenjauregui/?hl=es"
                          />
                           <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/LaurenJaureguiOfficial"
                          />
                           <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-twitter"
                            socialUrl="https://twitter.com/LaurenJauregui"
                          />
                           <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://linktr.ee/laurenjauregui"
                          />
                  </div>
                </div>
                
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Ene23/pambo.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Pambo
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/6ck4dwtkuGXEJEfWekkx8P`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                           <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/yosoypambo/?hl=es"
                          />
                           <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/yosoypambo/"
                          />
                           <ItemSocial
                            socialIcoName="Youtube"
                            socialIco="logo-youtube"
                            socialUrl="https://www.youtube.com/watch?v=Jd065BeXdQg&feature=youtu.be"
                          />
                  </div>
                </div>
                
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Ene23/halsey.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Halsey
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/26VFTg2z8YR0cCuwLzESi2`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                           <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/iamhalsey/"
                          />
                           <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-twitter"
                            socialUrl="https://twitter.com/halsey"
                          />
                           <ItemSocial
                            socialIcoName="TikTok"
                            socialIco="logo-tiktok"
                            socialUrl="https://www.tiktok.com/@halsey"
                          />
                  </div>
                </div>
                
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Ene23/girlinred.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Girl in Red
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/3uwAm6vQy7kWPS2bciKWx9`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                           <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/girlinred/?hl=es"
                          />
                           <ItemSocial
                            socialIcoName="Youtube"
                            socialIco="logo-youtube"
                            socialUrl="https://www.youtube.com/channel/UCwlHDQ83jgF1crd6XXzSmIA"
                          />
                           <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-twitter"
                            socialUrl="https://twitter.com/_girlinred_"
                          />
                  </div>
                </div>
                
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Ene23/omarapollo.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Omar Apollo
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/5FxD8fkQZ6KcsSYupDVoSO`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                           <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/omar.apollo/?hl=es"
                          />
                           <ItemSocial
                            socialIcoName="Youtube"
                            socialIco="logo-youtube"
                            socialUrl="https://www.youtube.com/channel/UCiVLSJ2MpNteP1oLYfu0VTw"
                          />
                           <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/OmarApollo"
                          />
                  </div>
                </div>
                
              </div>
            </div>
        </article>
        {/* Marzo */}
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Feb23/jesseyjoy.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Jesse y Joy
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/1mX1TWKpNxDSAH16LgDfiR`}
                        target="_blank" 
                      >
                        Spotify
                      </a>
                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                           <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/jesseyjoy/?hl=es"
                          />
                           <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://linkr.bio/jesseyjoy/"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Feb23/ladygaga.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Lady Gaga
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/album/4o7oZoaZrwElXdNXfBXsqe?si=DhwtfX-9TACJ1kPhx5gveg`}
                        target="_blank" 
                      >
                        Spotify
                      </a>
                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                           <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/ladygaga/?hl=es"
                          />
                           <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://ladygaga.lnk.to/HoldMyHand"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Feb23/mileycyrus.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Miley Cyrus
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/album/7I0tjwFtxUwBC1vgyeMAax?si=zKvFbXEqTVOkbeTq1dWRYw`}
                        target="_blank" 
                      >
                        Spotify
                      </a>
                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                           <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/mileycyrus/?hl=es"
                          />
                           <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://mileycyrus.lnk.to/Flowers"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Feb23/sofimayen.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
                Sofia Mayen
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/17fzKflH2VygyK3LCCN6Cn`}
                        target="_blank" 
                      >
                        Spotify
                      </a>
                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                           <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/soysofimayen/?hl=es"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/Feb23/teedinosaurs.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Totally Enormous Extinct Dinosaurs 
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/0g3NiCRhEv7M4SEDMrpItN?si=1iX7tWicSH6JOoGeaPfUXg&nd=1`}
                        target="_blank" 
                      >
                        Spotify
                      </a>
                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                           <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/teedinosaurs/?hl=es"
                          />
                           <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://linktr.ee/TEEDinosaurs"
                          />
                  </div>
                </div>
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/2023/Abril/robleis.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Robleis
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/32AuV4BxATEf2i7YVhIjht?si=mXQrMdUGQEOIO4K5bSzIxw&nd=1`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                           <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/robleis/?hl=es"
                          />
                           <ItemSocial
                            socialIcoName="YouTube"
                            socialIco="logo-YouTube"
                            socialUrl="https://www.youtube.com/channel/UCo27B6-j-cuAiBb9nd2d0iQ"
                          />
                           <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://orcd.co/motores"
                          />
                  </div>
                </div>
                
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/2023/Abril/esamipau.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Esa Mi Pau
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/2lmxVMvK3ROVKHvLg23fei?autoplay=true`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                           <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/esamipau/?hl=es"
                          />
                           <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/esamipauu/?locale=es_LA"
                          />
                           <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://soundcloud.com/esamipau?fbclid=IwAR3t3K0VKT-2mG5pWymb6lOXhM5ZAdoV6QVOIZEIyHJzMpis4n0XfeI-81I"
                          />
                  </div>
                </div>
                
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/2023/Abril/orvillepeck.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Orville Peck 
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/46auOkH1pk28rWrSoUNhLo?si=6GFnp0jUQkKUAUDcN-DCkg`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                           <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/orvillepeck/?hl=es"
                          />
                           <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/LaurenJaureguiOfficial"
                          />
                           <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://www.orvillepeck.com/?fbclid=IwAR3JKTD9E65yJZApPUU64FPIK-G5sl_TLxzXJ5aViM07ZkVVMiBW6qhEEgE"
                          />
                  </div>
                </div>
                
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/2023/Abril/kingprincess.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
               King Princess
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/6beUvFUlKliUYJdLOXNj9C?autoplay=true`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="Youtube"
                            socialIco="logo-youtube"
                            socialUrl="https://www.youtube.com/channel/UCd3ucTRSzlJYkANVcg73Dcw?feature=gws_kp_artist&feature=gws_kp_artist"
                          />
                           <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/kingprincess69/"
                          />
                           <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://www.kingprincessmusic.com/"
                          />
                  </div>
                </div>
                
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/2023/Abril/giawoods.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              Gia Woods
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/6T56xtTEllGW17snjAZLNP?autoplay=true`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                          <ItemSocial
                            socialIcoName="YouTube"
                            socialIco="logo-youtube"
                            socialUrl="https://www.youtube.com/c/giawoods"
                          />
                           <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/giawoods/?hl=es"
                          />
                           <ItemSocial
                            socialIcoName="Facebook"
                            socialIco="logo-facebook"
                            socialUrl="https://www.facebook.com/GiaWoodsOfficial/events/"
                          />
                  </div>
                </div>
                
              </div>
            </div>
        </article>
        <article className="col-lg-3 col-sm-6 mb-4">
          <div className="card shadows">
            <div className="box_content_img">
            <img src="https://stgkumbymx.blob.core.windows.net/ctnprimecontentmx/871/Imgs/PrideMusic/2023/Abril/rupaul.jpg" />
            </div>
            <div className="card-content text-center">
              <h6 className="card-title mb-0 text fnt_medium name_directory">
              RuPaul
              </h6> 
              {/* <h6 className="card-title mb-0 text fnt_small name_directory">
                  <i>Celebrando artistas femeninas de todo el mundo</i>
              </h6>  */}
              <br></br>
              <div className="box_data">
                  <h6 className="h_line text fnt_medium ">
                    <span>Enlaces</span>
                  </h6>
                  <div className="item_data_details">
                    <span className="ico_box_detail">
                    <i className="fab fa-spotify"></i>
                    </span>
                      <a
                        className="alinks text-break text-left"
                        href={`https://open.spotify.com/artist/2SdOKxC1sSxEyv8JYERaNe?autoplay=true`}
                        target="_blank" 
                      >
                        Spotify
                      </a>

                  </div>
                  <hr />
                  <div className="wrapper_social_links">
                           <ItemSocial
                            socialIcoName="Instagram"
                            socialIco="logo-instagram"
                            socialUrl="https://www.instagram.com/rupaulofficial/?hl=es"
                          />
                           <ItemSocial
                            socialIcoName="Twitter"
                            socialIco="logo-twitter"
                            socialUrl="https://twitter.com/RuPaul?ref_src=twsrc%5Etfw%7Ctwcamp%5Etweetembed%7Ctwterm%5E1641139828988456960%7Ctwgr%5E298359041149be116a0ad06fe75c42c0b89fe160%7Ctwcon%5Es1_&ref_url=https%3A%2F%2Flinktr.ee%2Frupaulofficial"
                          />
                           <ItemSocial
                            socialIcoName="Web"
                            socialIco="globe-outline"
                            socialUrl="https://linktr.ee/rupaulofficial"
                          />
                  </div>
                </div>
                
              </div>
            </div>
        </article>
      </div>
    </section>
  );
}
