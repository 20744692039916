import React from "react";

export function Legals() {
  return (
    <div className="links_legals">
      <div className="content_legals">
        <a target="_blank" href="/ambienteseguro.pdf">
          Decálogo
        </a>
        <a target="_blank" href="/avisoprivacidad.pdf">
          Aviso de Privacidad
        </a>
        <a target="_blank" href="/terminosycondiciones.pdf">
          Términos y Condiciones
        </a>
      </div>
    </div>
  );
}
