import React, { useState } from "react";
import "./rewards.scss";

import { ItemRewardCupon } from "./itemRewardCupon";

export function WrapperCategoriesRewards({
  reward,
  rewardCategory,
  rewardProducts,
  getRewardProducts,
}) {
  const [categoryActive, setCategoryActive] = useState(0);

  return (
    <div className="wrapper_category padd pt-1">
      <div className="box_tag_category">
        <div
          className={`item_tab_category ${
            categoryActive === 0 ? "active" : ""
          }`}
          onClick={() => {
            getRewardProducts(0);
            setCategoryActive(0);
          }}
        >
          <span className="tag_ico">
            <i className="fas fa-box-open"></i>
          </span>
          <span className="tag_name">Todos</span>
        </div>
        {rewardCategory.map((item) => (
          <div
            className={`item_tab_category ${
              categoryActive === item.rewardCategoryId ? "active" : ""
            }`}
            key={item.rewardCategoryId}
            onClick={() => {
              getRewardProducts(item.rewardCategoryId);
              setCategoryActive(item.rewardCategoryId);
            }}
          >
            <span className="tag_ico">
              <i className={item.urlIcon}></i>
            </span>
            <span className="tag_name">{item.description}</span>
          </div>
        ))}
      </div>
      <div className=" box_category_items">
        {rewardProducts.map((item) => (
          <ItemRewardCupon
            setCategoryActive={setCategoryActive}
            getRewardProducts={getRewardProducts}
            reward={reward}
            {...item}
          />
        ))}
      </div>
    </div>
  );
}
